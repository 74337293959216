@import '@/styles/variables.scss';






























.forbidden {
  margin-top: 140px;
  &-title {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #2830ce;
    max-width: 540px;
    margin: 24px auto 40px;
  }
}
